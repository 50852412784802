/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CmsPageComponentProps } from '@scandipwa/scandipwa/src/route/CmsPage/CmsPage.type';
import { store } from '@scandipwa/scandipwa/src/util/Store';
import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Html from 'Component/Html';
import SlickSliderComponent from 'Component/SlickSlider/SlickSlider.component';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';
import { ReactElement } from 'Type/Common.type';

import NewsletterSubscription from '../../component/NewsletterSubscription';
import {
    AboutCollaborationsSettings,
    CustomImageSliderLoopSettings,
    customImageSliderSettings,
    HomeBlogSectionSettings, HomeBrandBannersSettings,
    HomeServicesCardsSettings, HomeTopCategoriesSettings,
} from './CmsPage.constants';

import './CmsPage.style';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/** @namespace TrouperPwa/Route/CmsPage/Component */
export class CmsPageComponent extends PureComponent<CmsPageComponentProps> {
    static defaultProps: Partial<CmsPageComponentProps> = {
        isBreadcrumbsActive: true,
    };

    componentDidUpdate = () => {
        this.renderNewsletterBlock();
    };

    initializeCarousel = () => {
        const timeoutNumber = 100;
        const mobileBreakpoint = window.matchMedia('(max-width: 768px)').matches;
        setTimeout(() => {
            // Select all custom image slider elements
            const customImageSliders = document.querySelectorAll('.custom-image-slider');

            customImageSliders.forEach((CustomImageSlider) => {
                /* eslint-disable max-len */
                const CustomImageSliderItems = CustomImageSlider && CustomImageSlider.querySelectorAll('.custom-image-slider figure:not(.slider-bg-image)');

                if (CustomImageSliderItems.length > 0) {
                    // eslint-disable-next-line react/no-deprecated
                    ReactDOM.render(
                <Provider store={ store }>
                    <SlickSliderComponent
                      { ...customImageSliderSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      categoryItems={ Array.from(CustomImageSliderItems) } // Convert NodeList to Array
                    />
                </Provider>,
                CustomImageSlider, // Render the component in the current slider element
                    );
                }
            });

            // Select all custom image slider loop elements
            const customImageSliderLoops = document.querySelectorAll('.custom-image-slider-loop');
            customImageSliderLoops.forEach((CustomImageSliderLoop) => {
                /* eslint-disable max-len */
                const CustomImageSliderLoopItems = CustomImageSliderLoop && CustomImageSliderLoop.querySelectorAll('.custom-image-slider-loop figure:not(.slider-bg-image)');

                if (CustomImageSliderLoopItems.length > 0) {
                    // eslint-disable-next-line react/no-deprecated
                    ReactDOM.render(
                        <Provider store={ store }>
                            <SlickSliderComponent
                              { ...CustomImageSliderLoopSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                              categoryItems={ Array.from(CustomImageSliderLoopItems) } // Convert NodeList to Array
                            />
                        </Provider>,
                        CustomImageSliderLoop, // Render the component in the current loop
                    );
                }
            });

            const HomeTopCategories = document.querySelector('.top-categories-section .category-slider') as HTMLElement;
            const categoryItems = HomeTopCategories && HomeTopCategories.querySelectorAll('.category-item');

            if (categoryItems && categoryItems.length > 0) {
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(<Provider store={ store }>
                    <SlickSliderComponent
                      { ...HomeTopCategoriesSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      categoryItems={ Array.from(categoryItems) }
                    />
                                </Provider>, HomeTopCategories);
            }

            if (mobileBreakpoint) {
                /* eslint-disable max-len */
                const HomeBrandBanners = document.querySelector('.home-brand-banners .pagebuilder-column-line') as HTMLElement;
                /* eslint-disable max-len */
                const BannerItems = document.querySelectorAll('.home-brand-banners .pagebuilder-column-line .pagebuilder-column');

                if (BannerItems && BannerItems.length > 0) {
                    // eslint-disable-next-line react/no-deprecated
                    ReactDOM.render(
                        <Provider store={ store }>
                            <SlickSliderComponent
                              { ...HomeBrandBannersSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                              categoryItems={ Array.from(BannerItems) }
                            />
                        </Provider>,
                        HomeBrandBanners,
                    );
                }
            }

            const HomeServicesCards = document.querySelector('.home-services-cards .pagebuilder-column') as HTMLElement;
            const HomeServicesCardItems = HomeServicesCards && HomeServicesCards.querySelectorAll('[data-content-type="text"]');

            if (HomeServicesCardItems && HomeServicesCardItems.length > 0) {
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    <Provider store={ store }>
                        <SlickSliderComponent
                          { ...HomeServicesCardsSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                          categoryItems={ Array.from(HomeServicesCardItems) }
                        />
                    </Provider>,
                    HomeServicesCards,
                );
            }

            const HomeBlogSection = document.querySelector('.home-blog-section .blog-slider-cardRow') as HTMLElement;
            const HomeBlogSectionItems = HomeBlogSection && HomeBlogSection.querySelectorAll('.blog-slide-item');

            if (HomeBlogSectionItems && HomeBlogSectionItems.length > 0) {
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    <Provider store={ store }>
                        <SlickSliderComponent
                          { ...HomeBlogSectionSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                          categoryItems={ Array.from(HomeBlogSectionItems) }
                        />
                    </Provider>,
                    HomeBlogSection,
                );
            }

            const AboutCollaborations = document.querySelector('.about-us-collaborations .pagebuilder-column') as HTMLElement;
            const AboutCollaborationsItems = AboutCollaborations && AboutCollaborations.querySelectorAll('.about-us-collaborations .pagebuilder-column figure[data-content-type="image"]');

            if (AboutCollaborationsItems && AboutCollaborationsItems.length > 0) {
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    <Provider store={ store }>
                        <SlickSliderComponent
                          { ...AboutCollaborationsSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                          categoryItems={ Array.from(AboutCollaborationsItems) }
                        />
                    </Provider>,
                    AboutCollaborations,
                );
            }
        }, timeoutNumber);
    };

    renderNewsletterBlock(): void {
        const newsletterValidateDetail = document.getElementById('newsletter-validate-detail');

        if (newsletterValidateDetail && !document.getElementById('newsletter-validate-detail-block')) {
            const container = document.createElement('div');
            container.setAttribute('id', 'newsletter-validate-detail-block');
            newsletterValidateDetail.insertAdjacentElement('afterend', container);

            const newsletterValidateBlock = document.getElementById('newsletter-validate-detail-block');

            if (newsletterValidateBlock) {
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    <Provider store={ store }>
                        <div block="newsletterLabel">
                            <span block="label">Subscribe to our newsletter</span>
                        </div>
                        <NewsletterSubscription key="NewsletterSubscription" />
                    </Provider>,
                    newsletterValidateBlock,
                );
            }
        }
    }

    renderHeading(): ReactElement {
        const { cmsPage: { content_heading }, isLoading } = this.props;

        if (!content_heading || isLoading) {
            return null;
        }

        return (
            <h1 block="CmsPage" elem="Heading">
                <TextPlaceholder content={ content_heading } />
            </h1>
        );
    }

    renderContent(): ReactElement {
        const {
            isLoading,
            cmsPage: { content },
        } = this.props;

        if (isLoading) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!content) {
            return null;
        }

        return (
            <>
                <Html content={ content } />
                { this.initializeCarousel() }
            </>
        );
    }

    cmsAccordion = () => {
        const slTime = 500;
        // Add the 'acc-plus' class to all Accordion-Title elements
        const columnTitles = document.querySelectorAll('.Accordion-Title');
        columnTitles.forEach((title:any) => {
            title.classList.add('acc-plus');
            const customAccordion = title.closest('.Accordion-Column').querySelector('.Accordion-Content');
            customAccordion.style.display = 'none'; // Initially hide all content (acc-plus state)
        });
    
        // Iterate over each Accordion-Title to handle the initial state
        const columns = document.querySelectorAll('.Accordion-Column > .Accordion-Title') as any;
        columns.forEach((title:any) => {
            const customAccordion = title.closest('.Accordion-Column').querySelector('.Accordion-Content') as any;
    
            if (title.classList.contains('active')) {
                customAccordion.style.display = 'block';
                title.classList.remove('acc-plus');
                title.classList.add('acc-minus');
            } else {
                customAccordion.style.display = 'none';
                title.classList.remove('acc-minus');
                title.classList.add('acc-plus');
            }
    
            // Add the click event listener only if it hasn't been added already
            if (!title.hasAttribute('data-listener')) {
                title.addEventListener('click', function handleClick() {
                    const customAccordion = title.closest('.Accordion-Column').querySelector('.Accordion-Content') as any;
                    // Remove 'active' and reset state for other titles
                    columns.forEach((otherTitle:any) => {
                        if (otherTitle !== title) {
                            otherTitle.classList.remove('active');
                            otherTitle.classList.remove('acc-minus');
                            otherTitle.classList.add('acc-plus');
                            const otherAccordion = otherTitle.closest('.Accordion-Column').querySelector('.Accordion-Content') as any;
                            otherAccordion.style.display = 'none'; // Hide other accordion content
                        }
                    });
    
                    // Toggle the 'active' class on the clicked title
                    title.classList.toggle('active');
    
                    if (title.classList.contains('active')) {
                        // Slide down the accordion
                        customAccordion.style.transition = `height ${slTime}ms ease-in-out`;
                        customAccordion.style.height = `${customAccordion.scrollHeight}px`; // animate height expansion
                        title.classList.remove('acc-plus');
                        title.classList.add('acc-minus');
                        customAccordion.style.display = 'block'; // Show the accordion
                        customAccordion.style.height = 'auto'; // animate height collapse
                        customAccordion.style.overflow = 'hidden'; // animate height collapse
                    } else {
                        // Slide up the accordion
                        customAccordion.style.transition = `height ${slTime}ms ease-in-out`;
                        customAccordion.style.height = '0px'; // animate height collapse
                        title.classList.remove('acc-minus');
                        title.classList.add('acc-plus');
                        customAccordion.style.display = 'none'; // Hide the accordion
                    }
                });
    
                // Mark this element as having an event listener
                title.setAttribute('data-listener', 'true');
            }
        });
    };

    render(): ReactElement {
        const {
            cmsPage,
            isBreadcrumbsActive,
            isLoading,
        } = this.props;
        const { page_width, title, content } = cmsPage;

        if (!isLoading && !title && !content) {
            return <NoMatch />;
        }

        return (
            <><main block="CmsPage" mods={{ isBreadcrumbsHidden: !isBreadcrumbsActive }}>
                <div block="CmsPage" elem="Wrapper" mods={{ page_width }}>
                    {this.renderHeading()}
                    <div block="CmsPage" elem="Content">
                        {this.renderContent()}
                    </div>
                </div>
            </main><div id="intialise-accordion" aria-hidden="true" onClick={() => { this.cmsAccordion(); } }></div></>
        );
    }
}

export default CmsPageComponent;
